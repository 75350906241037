import React, { ReactNode } from 'react';
import { Box, Card, CardContent, Grid, Link, Typography } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';

type ContactInfoProps = {
  icon: ReactNode;
  text: string;
  href: string;
};

type ContactCardProps = {
  title: string;
  phoneNumber: string;
  email: string;
};

const ContactInfo = ({ icon, text, href }: ContactInfoProps) => (
  <Link
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '1rem',
      paddingTop: 1,
      textDecoration: 'none'
    }}
    href={href}
  >
    {icon}
    <Typography sx={{ marginLeft: 1 }}>{text}</Typography>
  </Link>
);

export default ({ title, phoneNumber, email }: ContactCardProps) => (
  <Grid item xs={12} sm={6} md={4}>
    <Card variant='outlined'>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography sx={{ fontWeight: 'bold' }} gutterBottom>
          {title}
        </Typography>
        <Box>
          <ContactInfo icon={<CallIcon />} text={phoneNumber} href={`tel:${phoneNumber}`} />
          <ContactInfo icon={<EmailIcon />} text={email} href={`mailto:${email}`} />
        </Box>
      </CardContent>
    </Card>
  </Grid>
);
